import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateInterval, DateIntervalKeys } from '../../models/DateInterval';
import DropdownSelect from '../shared/form-control/DropdownSelect';
import { Input } from '../shared/form-control/Input';
import StandardModal from '../shared/modal/variants/StandardModal';
import { ModalContext } from '../../contexts/ModalContext';

type DateIntervalEditorProps = {
  title: string;
  amount: number;
  interval: DateInterval;
  onSave: (amount: number | null, interval: DateInterval | null) => void;
  open: boolean;
  onClose: () => void;
};

const DateIntervalEditor: FC<DateIntervalEditorProps> = (props) => {
  const { amount, interval, open, onSave, onClose, title } = props;
  const [amountInternal, setAmountInternal] = useState(amount);
  const { t } = useTranslation(['common']);
  const intervals = [
    { id: DateInterval.DAY.toString(), value: DateInterval.DAY, text: t(DateIntervalKeys[DateInterval.DAY]) },
    { id: DateInterval.WEEK.toString(), value: DateInterval.WEEK, text: t(DateIntervalKeys[DateInterval.WEEK]) },
    { id: DateInterval.MONTH.toString(), value: DateInterval.MONTH, text: t(DateIntervalKeys[DateInterval.MONTH]) },
    { id: DateInterval.YEAR.toString(), value: DateInterval.YEAR, text: t(DateIntervalKeys[DateInterval.YEAR]) },
  ];
  const [selectedInterval, setSelectedInterval] = useState(intervals.find((x) => (x.value as DateInterval) === interval) || intervals[0]);

  const reset = () => {
    setAmountInternal(0);
    setSelectedInterval(intervals[0]);
  };

  return (
    <>
      <ModalContext.Provider value={{ open: open, onClose: onClose, modalWidth: 'w-80' }}>
        <StandardModal
          title={title}
          cancelButtonTitle={t('common:modals.cancel')}
          confirmButtonTitle={t('common:modals.save')}
          onCancelClick={onClose}
          onConfirmClick={() =>
            onSave(amountInternal === 0 ? null : amountInternal, amountInternal === 0 ? null : (selectedInterval.value as DateInterval))
          }
        >
          <div className="flex justify-center gap-4">
            <div className="w-24">
              <Input
                type="number"
                value={`${amountInternal}`}
                min={0}
                onChange={(event) => {
                  setAmountInternal(parseInt(event.target.value));
                }}
              />
            </div>
            <div className="text-right">
              <DropdownSelect
                className="w-40 text-left"
                value={selectedInterval}
                options={intervals}
                onChange={(option) => {
                  setSelectedInterval(option);
                }}
              />
              <span className="text-dpm-14 cursor-pointer text-right underline" onClick={reset}>
                {t('common:date.reset')}
              </span>
            </div>
          </div>
        </StandardModal>
      </ModalContext.Provider>
    </>
  );
};

export default DateIntervalEditor;
